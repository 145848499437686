<template>
    <div
      class="container-fluid bg-dark text-white-50 py-2 px-0 d-none d-lg-block"
    >
      <div class="row gx-0 align-items-center">
        <div class="col-lg-7 px-5 text-start">
          <div class="h-100 d-inline-flex align-items-center me-4">
            <small class="fa fa-phone-alt me-2"></small>
            <small>{{ lang.phone_number }}</small>
          </div>
          <div class="h-100 d-inline-flex align-items-center me-4">
            <small class="far fa-envelope-open me-2"></small>
            <small>info@dcit.ly</small>
          </div>
        </div>

        <div class="col-lg-5 px-5 text-end">
          <div class="h-100 d-inline-flex align-items-center">
            <a class="text-white-50 ms-4" target="_blank" href="https://www.facebook.com/DamanInfoTech"
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a class="text-white-50 ms-4" target="_blank" href="https://twitter.com/DamanInfoTech"
              ><i class="fab fa-twitter"></i
            ></a>
            <a class="text-white-50 ms-4" target="_blank" href="https://www.linkedin.com/company/damn-information-technology/"
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>

          <div class="align-items-center border-bottom d-inline-flex h-100 ms-4 p-1 rounded">
            <small class="far fa-clock me-2"></small>
            <small>{{ time }}</small>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'TopBar',
  props: {
    lang: Object,
  },
  data() {
    return {
      interval: null,
      time: '--:--:--',
    }
  },
  beforeUnmount() {
    // prevent memory leak
    clearInterval(this.interval)
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)
  }
}
</script>
